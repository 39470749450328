import React from 'react';

import Typograpy from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import SectionTitle from '@/components/common/basic/SectionTitle';
import PolicyDisplay from '../basic/PolicyDisplay';
import LinkButton from '../basic/LinkButton';

const useStyles = makeStyles({
  section: {
    margin: `calc(1rem + 2vw) auto`,
  },
  buttonWrapper: {
    margin: `1.5rem auto`,
    textAlign: `center`,
  },
});

const SimplePolicies: React.VFC = () => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Policy" title="4つの政策" />
      <Typograpy>藤川たかしは、4つの政策で日本を改革します。</Typograpy>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="01. 腐敗を浄化する政治改革" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="02. 社会保障の抜本改革" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="03. 成長戦略・規制改革" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="04. 教育無償化と教育改革" />
        </Grid>
      </Grid>
      <div className={classes.buttonWrapper}>
        <LinkButton name="詳しい政策はこちら" linkTo="policies" />
      </div>
    </section>
  );
};

export default SimplePolicies;
